import React, { useState, useEffect } from 'react';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory , useLocation } from 'react-router-dom';
import axios from 'axios';
import ReactDOM from 'react-dom';
import Header from './Header';
import Footer from './Footer';
import BannerSlider from './BannerSlider';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules';
const BASE_URL=process.env.REACT_APP_BASE_URL;
const Home = () => {
	const navigate = useNavigate();
	const [jobData, setJobData] = useState([]);
	const [msgData, setMsgData] = useState('');
	const [candidate, setCandidate] = useState(0);
	const [options, setOptions] = useState([]);
	useEffect(() => {
		// Fetch designations from the server
		const fetchDesignations = async () => {
		  try {
			const response = await fetch(`${BASE_URL}getDesignations`, {
			  method: 'GET',
			  headers: {
				'Content-Type': 'application/json',
			  },
			});
	
			if (response.ok) {
			  const data = await response.json();
			  setOptions(data); // Populate options with fetched designations
			} else {
			  console.error('Failed to fetch designations');
			}
		  } catch (error) {
			console.error('Error fetching designations:', error);
		  }
		};
	
		fetchDesignations();
	  }, []);
	useEffect(() => {
		const fetchJobs = async () => {
		  try {
			const response = await fetch(`${BASE_URL}getJobsAll`, {
			  method: 'POST',
			  headers: {
				'Content-Type': 'application/json'
			  }
			});
	
			if (response.ok) {
			  const data = await response.json();
			  setJobData(data); // Set fetched data to state
			} else {
			  setMsgData('No Result Found, Please Try another');
			  console.error('No Result for such Criteria.');
			}
		  } catch (error) {
			setMsgData('No Result Found, Please Try another');
			console.error('Error submitting form', error);
		  }
		};
	
		fetchJobs(); // Call the function to fetch data when the component mounts
	  }, []); // Empty dependency array to run only once on component mount
	  const concatenateStrings = (str1, str2, str3) => {
		
		const parts = [str1, str2, str3].filter(part => part !== null && part !== undefined && part !== '');

		//alert(parts);
		return parts.join('/');
	  };	
	  function formatDate(dateString) {
		const date = new Date(dateString);
		const day = date.getDate();
		const month = date.toLocaleString('default', { month: 'long' });
		const year = date.getFullYear();
	  
		// Get the ordinal suffix for the day (e.g., 1st, 2nd, 3rd, etc.)
		const getOrdinalSuffix = (day) => {
		  if (day > 3 && day < 21) return 'th'; // for 11th, 12th, 13th, etc.
		  switch (day % 10) {
			case 1: return 'st';
			case 2: return 'nd';
			case 3: return 'rd';
			default: return 'th';
		  }
		};
	  
		return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
	  } 
	  const handleClick = async (e,idd,desigid) => {
	
		e.preventDefault();
	
	//alert(JSON.stringify(formData));
	
	const formData={
		jobId: idd,
		designationId: desigid,
		candidateId:candidate
	  };
	 // alert(JSON.stringify(formData));
		try {
			
		  //const response = await fetch('http://localhost:8000/api/getJobDetails', {
		  const response = await fetch(`${BASE_URL}getJobDetails`, {
			
		 	method: 'POST',
		 	headers: {
		 	  'Content-Type': 'application/json'
		 	},
			 body: JSON.stringify(formData)
		   });
		   if (response.ok) {
		
		 	const data = await response.json();
			 sessionStorage.setItem('jobdetails', JSON.stringify(data));
			navigate('/jobdetails', { state: { response: data } });
			
		   } else {
		 	console.error('Failed to submit form');
		   }
		} catch (error) {
		  console.error('Error submitting form', error);
		}
	  };
	  const handleSubmit = async (item) => {
		// Assuming formData will now include selected `item` data
		const selectedFormData = {
		  skill: item.id,
		  location: '',
		  exp: ''
		};
	  
		console.log('Submitting form data:', selectedFormData); // For debugging
	  
		try {
		  const response = await fetch(`${BASE_URL}getJobs`, {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify(selectedFormData),  // Send updated formData
		  });
	  
		  if (response.ok) {
			const data = await response.json();
			sessionStorage.setItem('jobs', JSON.stringify(data));
			navigate('/jobsearch', { state: { response: data } });
		  } else {
			setMsgData('No Result Found, Please Try another');
			console.error('No result for such criteria.');
		  }
		} catch (error) {
		  setMsgData('No Result Found, Please Try another');
		  console.error('Error submitting form', error);
		}
	  };
	  const job_grid_container = {
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 1fr)', /* 3 columns */
		gap: '20px', /* Spacing between items */
		padding: '20px'
	  };
	  
  return (
    <div>
        <Header />
		<BannerSlider />
		<section className="clint-logo-3 section-p">
    <div className="container">
        <div className="row justify-content-center">
		<div className="section-head text-center">
                    <h2>Top Recruiters</h2>
                </div>
                <div className="row">
 					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/samsung.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/madina.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/sts.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/altradcape.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/descon.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/bec.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/kaefer.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/drydocks.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/arabian.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/qcon.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/alshaya.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/baskinrobbins.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/adyard.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/albalagh.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/almulla.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/arabianmep.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/binladin.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/doosan.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/emrill.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/galfar.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/khansaheb.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/kier.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/lamprell.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/limak.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/limak-.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/mcsc.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/nbtc.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/satorp.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/smasco.jpg" alt="" /> </div>
					<div className="col-lg-2"><img className="clientele-logo"  src="assets/images/clients/SAS.jpg" alt="" /> </div>
				</div>
			</div>
		</div>
	
</section>
 

		{/* <section className="clint-logo-3 section-p">
      <div className="container">
        <div className="row justify-content-center">
          <div className="section-head text-center">
            <h2>Top Recruiters</h2>
          </div>
          <div className="swiper-container clint-logo-3-slider">
            <Swiper
              loop={true}
              effect="slide"
              speed={900}
              autoplay={{ delay: 1500 }}
              pagination={{ clickable: true }}
              slidesPerView={5}
			  modules={[Autoplay, EffectFade]}
              
            >
              <SwiperSlide><img className="clientele-logo" src="assets/images/clients/samsung.jpg" alt="" /></SwiperSlide>
              <SwiperSlide><img className="clientele-logo" src="assets/images/clients/madina.jpg" alt="" /></SwiperSlide>
              <SwiperSlide><img className="clientele-logo" src="assets/images/clients/sts.jpg" alt="" /></SwiperSlide>
              <SwiperSlide><img className="clientele-logo" src="assets/images/clients/altradcape.jpg" alt="" /></SwiperSlide>
        
					
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/descon.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/bec.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/kaefer.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/drydocks.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/arabian.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/qcon.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/alshaya.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/baskinrobbins.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/adyard.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/albalagh.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/almulla.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/arabianmep.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/binladin.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/doosan.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/emrill.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/galfar.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/khansaheb.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/kier.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/lamprell.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/limak.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/limak-.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/mcsc.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/nbtc.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/satorp.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/smasco.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/SAS.jpg" alt=""/></SwiperSlide>
						<SwiperSlide><img className="clientele-logo"  src="assets/images/clients/transguard.jpg" alt=""/></SwiperSlide>
                 </Swiper>   
                </div>
            </div>
        </div>
  
</section> */}

<section className="counter-3-part section-p">
    <div className="container">
        <div className="row">
            <div className="col-12 text-center">
                <div className="section-head light">
                    <h2>Our Experience</h2>
                </div>
            </div>
        </div>
        <div className="row align-items-center justify-content-center">
			<div className="col-lg-10">
			   <div className="row">
			
					<div className="col-md-3 col-6 text-center">
						<div className="counter-3-item">
							<div className="number-box">
								<i className="flaticon-map-pin-marked"></i>
							</div>
							<div className="number-box">
								<h2 className="white counter">15</h2>
								<h3>Branches</h3>
							</div>
						</div>
					</div>
				
					<div className="col-md-3 col-6 text-center">
						<div className="counter-3-item">
							<div className="number-box">
								<i className="flaticon-network"></i>
							</div>
							<div className="number-box">
								<h2 className="white counter">600000</h2>
								<h3>Mobilization</h3>
							</div>
						</div>
					</div>
		
					<div className="col-md-3 col-6 text-center">
						<div className="counter-3-item">
							<div className="number-box">
								<i className="fa fa-handshake-o" aria-hidden="true"></i>
							</div>
							<div className="number-box">
								<h2 className="white counter">350</h2>
								<h3>Clients</h3>
							</div>
						</div>
					</div>
					
					<div className="col-md-3 col-6 text-center">
						<div className="counter-3-item">
							<div className="number-box">
								<i className="fa fa-globe"></i>
							</div>
							<div className="number-box">
								<h2 className="white counter">30</h2>
								<h3>Countries</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
    </div>
</section>
<section className="clint-logo-3 section-p">
      <div className="container">
        <div className="row justify-content-center">
          <div className="section-head text-center">
            <h2>Recommended jobs for you</h2>
			 {msgData && <p className="text-center pt-5">{msgData}</p>}
          </div>
		  </div>
		  <div className="row justify-content-center">
<div style={job_grid_container}>
  {jobData.length > 0 ? (
    jobData.slice(0, 12).map((job, index) => (
      <div  key={index}>
        <a href="javascript:void(0)" onClick={(e) => handleClick(e, job.id, job.designationid)}>
          {/* <div className="team-2-item text-center"> */}
            <div className="cust-job-tuple layout-wrapper lay-1">
              
              <div className="row2">
                <a className="title">{job.jobtitle}</a>
              </div>
              <div className="row3">
                <span className="comp-dtls-wrap">
                  <span className="comp-name mw-100">
                    {concatenateStrings(`${job.requiredqualification1}`, `${job.requiredqualification2}`, `${job.requiredqualification3}`)}
                  </span>
                </span>
              </div>
              <div className="row4">
                <div className="job-details">
                  <span className="loc-wrap fa fa-map-marker">
                    <span className="loc">{job.location_name}</span>
                  </span>
                </div>
              </div>
			  <div className="row1" style={{ width: "250px", textAlign: "left" }}>
                <span className="job-post-day">
                  Posted On: {formatDate(`${job.posteddate}`)} <br /> Openings: {job.numberofposts}
                </span>
              </div>
			  
            </div>
			{/* 
          </div> */}
        </a>
      </div>
    ))
  ) : (
    <div className="row"> <div className="col-lg-12 text-center"><p>No jobs available</p></div></div>
  )}
</div>



          {/* <div className="swiper-container clint-logo-3-slider">
            <Swiper
              loop={true}
              effect="slide"
              speed={900}
              autoplay={{ delay: 500 }}
              pagination={{ clickable: true }}
              slidesPerView={5}
			  modules={[Autoplay,Pagination, EffectFade]} 
            >
				{jobData.length > 0 ? (
        jobData.map((job, index) => (
       

						<SwiperSlide key={index}>
						<div className="swiper-slide">
						<a href="javascript:void(0);" onClick={(e) => handleClick(e, job.id,job.designationid)}>
                            <div className="team-2-item text-center">
                                <div className="cust-job-tuple layout-wrapper lay-1 ">
									<div className=" row1"  style={{width:"250px",textAlign:"left"}}>
										
										<span className="job-post-day ">Posted On: {formatDate(`${job.posteddate}`)}  <br /> Openings: {job.numberofposts}</span>
									</div>
									<div className=" row2"><a className="title ">{job.jobtitle}</a></div>
									<div className=" row3">
										<span className=" comp-dtls-wrap"><span className=" comp-name mw-100">{concatenateStrings(`${job.requiredqualification1}`,`${job.requiredqualification2}`,`${job.requiredqualification3}`)}</span>
										</span>
									</div>
									<div className=" row4">
										<div className="job-details "><span className="loc-wrap fa fa-map-marker"><span className=" loc">{job.location_name}</span></span></div>
									</div>
								</div>
                            </div>
							</a>
                        </div>
						</SwiperSlide>
			
        ))
      ) : (
        <p>No jobs available</p>
      )}	
						
                 </Swiper>   
                </div> */}
            </div>
        </div>
  
</section>
    <section className="practise-part section-p">
        <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <div className="section-head">
                        <h2>POPULAR SEARCHES</h2>
                    </div>
                </div>
            </div>
        <div className="row align-items-center justify-content-center">
			<div className="col-lg-12">
			    <div className="searchlist">
				
					{options.map((item, index) => (
            <div className="searchitem" key={index}>
              <a href="#!" className="Searchitemsview" onClick={() => handleSubmit(item)} >{item.designation}</a>
            </div>
          ))}
				
				</div>
            </div>
        </div>
		</div>
    </section>


<section className="blog-area section-p">
	<div className="container">
			<div className="row">
                <div className="col-12 text-center">
                    <div className="section-head">
                        <h2>LATEST BLOGS</h2>
                    </div>
                </div>
            </div>
		<div className="row">
		
			<div className="col-md-6 col-lg-6 col-xl-6">
				<div className="sin-blog-two justify-content-center align-items-center row no-gutters">
					<div className="col-xl-6">
						<div className="blog-img-two ">
							<img src="https://www.seagullgroup.in/uploads/career/172209741120240727162331.jpg" alt="" />
						</div>
					</div>
					<div className="col-xl-6">
						<div className="blog-content-two-wrap ">
							<div className="blog-con-two">
								<h2 className="mb-2"><a  target="_blank"  href="https://seagullgroup.in/blog/10/india%27s-ambitious">India's Ambitious Skill Development Initiatives: Boosting Economy and Empowering Youth:</a></h2>

								<a className="text-danger"  target="_blank"  href="https://seagullgroup.in/blog/10/india%27s-ambitious">Click here to Read blog</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div className="col-md-6 col-lg-6 col-xl-6">
				<div className="sin-blog-two justify-content-center align-items-center row no-gutters">
					<div className="col-xl-6">
						<div className="blog-img-two ">
							<img src="https://www.seagullgroup.in/uploads/career/171168867120240329050431.jpg" alt="" />
						</div>
					</div>
					<div className="col-xl-6">
						<div className="blog-content-two-wrap ">
							<div className="blog-con-two">
								<h2 className="mb-2"><a target="_blank" href="https://seagullgroup.in/blog/9/living-in-ai-era">Living in the AI Era: Embracing the Future:</a></h2>
								<a  className="text-danger"  target="_blank"  href="https://seagullgroup.in/blog/9/living-in-ai-era">Click here to Read blog</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		
			
		</div>
	</div>
</section>


        <Footer />
    </div>
  )
}

export default Home