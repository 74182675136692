import React from 'react'

const Footer = () => {
    const currentYear = new Date().getFullYear(); 
  return (
    <div>
        <footer className="footer-part footer-bg-light">
    <div className="footer-widget">
        <div className="container">
            <div className="row">

                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-logo text-center">
                        <a href="/"><img src="assets/images/logo.png" alt="Logo" /></a>
                       <p className="text-muted pt-2">Connect with us</p>
                        <ol className="flat-list pt-2">
                            <li><a href="#"><i className="fab fa-facebook"></i></a></li>
                            <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                            <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                            <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
                        </ol>
                    </div>
                </div>
        
                <div className="col-6 col-sm-6 col-lg-2 mt-4 mt-sm-0">
                    <div className="footer-widget-item">
                        <ul className="footer-widget-link pe-3">
                            <li><a href="#"><i className="fa fa-angle-double-right"></i> About us</a></li>
                            <li><a href="#"><i className="fa fa-angle-double-right"></i> Careers</a></li>
                            <li><a href="#"><i className="fa fa-angle-double-right"></i> Employer home</a></li>
                            <li><a href="#"><i className="fa fa-angle-double-right"></i> Sitemap</a></li>
                            <li><a href="#"><i className="fa fa-angle-double-right"></i> Credits</a></li>
                        </ul>
                    </div>
                </div>
                
                <div className="col-6 col-sm-6 col-lg-2 mt-4 mt-lg-0">
                   <div className="footer-widget-item">
						<ul className="footer-widget-link">
							<li><a href="#"><i className="fa fa-angle-double-right"></i> Help center</a></li>
							<li><a href="#"><i className="fa fa-angle-double-right"></i> Summons/Notices</a></li>
							<li><a href="#"><i className="fa fa-angle-double-right"></i> Grievances</a></li>
							<li><a href="#"><i className="fa fa-angle-double-right"></i> Report issue</a></li>
						</ul>
					</div>
				</div>
      
                <div className="col-6 col-sm-6 col-lg-2 mt-4 mt-lg-0">
                   <div className="footer-widget-item">
						<ul className="footer-widget-link">
							<li><a href="#"><i className="fa fa-angle-double-right"></i> Privacy & Policy</a></li>
							<li><a href="#"><i className="fa fa-angle-double-right"></i> Terms & conditions</a></li>
							<li><a href="#"><i className="fa fa-angle-double-right"></i> Fraud alert</a></li>
							<li><a href="#"><i className="fa fa-angle-double-right"></i> Trust & safety</a></li>
						</ul>
					</div>
				</div>
                 <div className="col-6 col-sm-6 col-lg-3 mt-4 mt-lg-0">
                   <div className="footer-widget-item">
                   <div className="nI-gNb-app-store"><div className="nI-gNb-title">Apply on the go</div>
                   <div className="nI-gNb-description">Get real-time job updates on our App</div>
                   <div className="nI-gNb-app-cont">
                    <a href="" rel="noreferrer" className="nI-gNb-appdownload" target="_blank">
                    <img loading="lazy" alt="naukri app download" src="https://static.naukimg.com/s/0/0/i/new-homepage/android-app_v1.png" height="40" width="149" />
                    </a>
                    <a href="https://itunes.apple.com/in/app/naukri.com-job-search/id482877505?mt=8" rel="noreferrer" className="nI-gNb-appdownload" target="_blank">
                    <img loading="lazy" alt="naukri app download" src="https://static.naukimg.com/s/0/0/i/new-homepage/ios-app_v1.png" height="40" width="149" />
                    </a>
                    </div>
                    </div>
					</div>
				</div>
            
        </div>
    </div>
</div>

<div className="footer-copyright">
    <div className="container">
        <div className="row">
            <div className="col-xl-12">
                <p>Copyright ©<span> {currentYear}</span> | All rights reserved <a href="#@!">Seagulljobs</a></p>
            </div>
        </div>
    </div>
</div>


</footer>
    </div>
  )
}

export default Footer